import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
// import SampleForm from '../components/Form'

class ContactPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div className="contact-page page-heading text-center">
          <div className="container">
            <h1 className="text-white m-0">Contact</h1>
          </div>
        </div>

        {/* <div className="container">
          <div className="col-md-6 offset-md-3 contact-form-container">
            <h4 className="text-center">
              Fill in the form below to send me an email.
            </h4>
            <hr />
            <SampleForm
              form_name="Newsletter Form"
              form_id="5bcd42f86b63453b251972bc"
              form_classname="form-newsletter"
            >
              <div className="form-row">
                <div className="col-12 ">
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      name="Email Address"
                      id="email_address"
                    />
                    <label htmlFor="email_address">
                      Enter Your Email Address
                    </label>
                  </div>
                  <small className="text-dark form-text mt-0">
                    We will never share your email with anyone else.
                  </small>
                </div>
                <div className="col-12 ">
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="Name"
                      id="full_name"
                    />
                    <label htmlFor="full_name">Enter Your Name</label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group m-0 contact-dropdown">
                    <label className="position-relative p-0">
                      How did you hear about my dating service?
                    </label>
                    <select className="form-control" name="program">
                      <option value="Friend">Friend</option>
                      <option value="Wall Ad">Wall Ad</option>
                      <option value="Newspaper">Newspaper</option>
                      <option value="Radio">Radio</option>
                      <option value="The MBP Video">The MBP Video</option>
                      <option value="Facebook Ads">Facebook Ads</option>
                      <option value="TV">TV</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      className="form-control "
                      rows="3"
                      name="message"
                      id="message"
                    />
                    <label htmlFor="message">How can we help you?</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="webriq-recaptcha" />
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button className="btn btn-primary w-100" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </SampleForm>
          </div>
        </div> */}
      </Layout>
    )
  }
}
export default ContactPage

export const ContactPageQuery = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
